const { base58btc } = require('multiformats/bases/base58');

const eip712SchemaMap = {
  givenName:
    'https://myntfsid.mypinata.cloud/ipfs/QmXM1JReim4U736RSxHzn5rd2JJ3b8iikPkmHnCGE45KbH',
  familyName:
    'https://myntfsid.mypinata.cloud/ipfs/QmQqLWzLRwhgp8UwdaGSZfWb42my2gp4YoXHFHuGarYKFK',
  birthDate:
    'https://myntfsid.mypinata.cloud/ipfs/Qmb9YjjmeC2KHR5G1UUu3K6uTW2H3dtGd5fQELrYJjikbG',
  nationality:
    'https://myntfsid.mypinata.cloud/ipfs/QmYpYhYAbtWipDMaMYoCsSfydmkB2NRPwb5Lw46cFSTms1',
  gender:
    'https://myntfsid.mypinata.cloud/ipfs/Qmd4XZkXj9RsdZaEZavziAPM8quW72NrZNdZzkb3KyLayb',
  email:
    'https://myntfsid.mypinata.cloud/ipfs/QmfVApuBeHbvnqAk5c1FxEN1mZy15KmMbyvYyN1fR8hvXs',
  award:
    'https://myntfsid.mypinata.cloud/ipfs/QmQV6pQ4nzfA5o1jvLNwQpVgnTXF3KG9cNXmAuDDYgM5K4',
};
const obEip712Schema =
  'https://myntfsid.mypinata.cloud/ipfs/Qmdi7fC2EGp3kDWWX4dp6g3WQJ1V25QYo78nFcWbPobjk7';
const obRoleEip712Schema =
  'https://myntfsid.mypinata.cloud/ipfs/QmNcDEeR42KY3nN44N5SdPUgC7q2vPAtgrRLhDrUSUeqDn';
const obDelegationEip712Schema =
  'https://myntfsid.mypinata.cloud/ipfs/QmZgMKBF3Fp9rokyo7qsEbN6stRWZc4R11oiTT7dX9BxAd';

export function createVerifiableCredential(
  issuerDid,
  userDid,
  key,
  value,
  image
) {
  const verifiableCredential = {
    '@context': [
      'https://www.w3.org/2018/credentials/v1',
      'https://w3c-ccg.github.io/ethereum-eip712-signature-2021-spec/contexts/v1',
      'https://schema.org',
    ],
    type: ['VerifiableCredential'],
    issuer: issuerDid,
    issuanceDate: new Date().toISOString(),
    credentialSubject: {
      id: userDid,
      image,
      type: 'Person',
      [key]: value,
    },
    credentialSchema: {
      id: 'https://myntfsid.mypinata.cloud/ipfs/QmQVWmUBCLyuky9KeBAks2Ca8VT8CrHMnNDTSkrZAzSNji',
      type: 'MyDIDSchemaValidator2022',
    },
  };

  return verifiableCredential;
}

export async function addProofToVerifiableCredential(
  vcWithoutProof,
  issuerDid,
  signature
) {
  const { id, type, image, ...keyValue } = vcWithoutProof.credentialSubject;

  let eip712SchemaUrl = null;
  let primaryType = null;

  if (vcWithoutProof.type.indexOf('EndorsementCredential') != -1) {
    if (
      vcWithoutProof.credentialSubject.endorsementComment.indexOf(
        'DELEGATION'
      ) == 0
    )
      eip712SchemaUrl = obDelegationEip712Schema;
    else eip712SchemaUrl = obRoleEip712Schema;
    primaryType = 'EndorsementCredential';
  } else if (vcWithoutProof.type.indexOf('OpenBadgeCredential') != -1) {
    eip712SchemaUrl = obEip712Schema;
    primaryType = 'OpenBadgeCredential';
  } else {
    eip712SchemaUrl = eip712SchemaMap[Object.keys(keyValue)[0]];
    primaryType = 'VerifiableCredential';
  }

  const proof = {
    type: 'EthereumEip712Signature2021',
    created: new Date().toISOString().slice(0, 19) + 'Z',
    proofPurpose: 'assertionMethod',
    verificationMethod: issuerDid + '#ASSR_1',
    proofValue: hexToBase58btc(signature),
    eip712: {
      messageDataEip712Schema: eip712SchemaUrl,
      domain: {
        name: 'myDid',
        chainId: parseInt(process.env.VUE_APP_CHAIN_ID),
        version: '2',
      },
      primaryType,
    },
  };
  return { ...vcWithoutProof, proof };
}

function hexToBase58btc(value) {
  try {
    const byteArray = Uint8Array.from(
      Buffer.from(value.replace('0x', ''), 'hex')
    );
    return base58btc.encode(byteArray);
  } catch (e) {
    console.log(e);
    return value;
  }
}

