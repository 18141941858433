import Web3 from 'web3';
import sylContractABI from '/assets/sylContractABI.json';

const web3 = new Web3(process.env.VUE_APP_WEB3_PROVIDER);

const sylContract = new web3.eth.Contract(
  sylContractABI,
  process.env.VUE_APP_SYL_CONTRACT_ADDR
);

export async function createSylTransaction(from, methodName, ...args) {
  const inputData = sylContract.methods[methodName](...args).encodeABI();

  const gasPrice = parseInt(await web3.eth.getGasPrice());
  const estimateGas = await sylContract.methods[methodName](
    ...args
  ).estimateGas({ from });

  const rawTransaction = {
    from,
    to: sylContract.options.address,
    value: '0x0',
    gasPrice: '0x' + gasPrice.toString(16),
    gas: '0x' + Math.round(Number(estimateGas) * 1.1).toString(16),
    data: inputData,
    chainId: process.env.VUE_APP_CHAIN_ID,
  };

  return rawTransaction;
}

export async function getSylBalance(addr) {
  return (
    parseInt(await sylContract.methods.balanceOf(addr).call()) / Math.pow(10, 6)
  );
}

export async function verifySylAllowanceForMydid(addr, priceMethod) {
  // retrieve issuer SYL balance
  const sylBalance =
    parseInt(await sylContract.methods.balanceOf(addr).call()) /
    Math.pow(10, 6);

  // retrieve method SYL price
  const sylPrice =
    parseInt(await mydidContract.methods[priceMethod]().call()) /
    Math.pow(10, 6);

  // check if SYL allowance is sufficient
  const sylAllowanceMydid =
    parseInt(
      await sylContract.methods
        .allowance(addr, process.env.VUE_APP_MYDID_CONTRACT_ADDR)
        .call()
    ) / Math.pow(10, 6);
  if (sylAllowanceMydid < sylPrice) {
    return {
      state: false,
      price: sylPrice,
      code: 1,
      error: `SYL allowance insufficient`,
    };
  }

  // check if issuer SYL balance is sufficient
  if (sylBalance < sylPrice) {
    return {
      state: false,
      price: sylPrice,
      code: 2,
      error: `SYL balance insufficient`,
    };
  }

  return {
    state: true,
    price: sylPrice,
    code: 0,
  };
}
